<template>
    <v-col>
        Reiseunterlagen / Voucher
        Transferschein
    </v-col>
</template>

<script>

export default {
    name: 'VoucherAction',
    data: () => ({}),
    methods: {},
}
</script>