<template>
    <v-container>
        <v-row>
            <v-col cols="2">
                <Menu></Menu>
            </v-col>
            <v-col v-if="!loading">
                <v-stepper v-model="e1" alt-labels>
                    <v-stepper-header>
                        <v-stepper-step
                            :complete="e1 > 1"
                            step="1"
                        >
                            Buchung bestätigt
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step
                            :complete="e1 > 2"
                            step="2"
                        >
                            Bezahlt
                            100%
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">
                            Reiseunterlagen
                            versendet
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="4">
                            Anreise
                            am {{ booking.customValues.date_from | formatDate('DD.MM.YYYY') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="5">
                            Abreise
                            am {{ booking.customValues.date_till | formatDate('DD.MM.YYYY') }}
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items class="pa-2">
                        <div class="text-center">
                            <h2>{{ hotel.name }}</h2>
                            <div v-if="!readMore && hotel.description" v-html="stripHtml(hotel.description.slice(0, 200))"></div>
                            <a class="" v-if="!readMore && hotel.description" @click="activateReadMore" href="#">
                                Read more...
                            </a>
                            <div v-if="readMore" v-html="hotel.description"></div>
                        </div>

                        <v-row>
                            <v-col
                                v-for="name in hotel.images"
                                :key="name"
                                class="d-flex child-flex"
                                cols="3"
                            >
                                <v-img
                                    :src="apiUrl()+'/image/'+name"
                                    aspect-ratio="1"
                                    class="grey lighten-2"
                                >
                                    <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-col>
                        </v-row>

                        <v-row
                            align="center"
                            justify="space-around"
                            class="mb-2"
                        >
                            <v-btn depressed
                                   color="error"
                                   @click="selectOption('cancel')"
                            >Stornieren
                            </v-btn>
                            <v-btn @click="selectOption('change')"
                                   depressed
                            >Buchung ändern
                            </v-btn>
                            <v-btn @click="selectOption('invoice')"
                                   depressed
                                   color="primary"
                            >Rechnung
                            </v-btn>
                            <v-btn @click="selectOption('voucher')"
                                   depressed
                                   color="primary"
                            >Reiseunterlagen / Voucher
                            </v-btn>
                            <v-btn @click="selectOption('documents')"
                                depressed
                                color="primary"
                            >
                                weitere Unterlagen
                            </v-btn>
                        </v-row>
                        <v-row>
                            <cancel-action v-if="selectedOption=='cancel'"></cancel-action>
                            <change-action v-if="selectedOption=='change'"></change-action>
                            <voucher-action v-if="selectedOption=='voucher'"></voucher-action>
                            <documents-action v-if="selectedOption=='documents'"></documents-action>
                        </v-row>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
import Menu from "@/views/profile/menu";
import CancelAction from "@/views/profile/actions/CancelAction";
import ChangeAction from "@/views/profile/actions/ChangeAction";
import VoucherAction from "@/views/profile/actions/VoucherAction";
import DocumentsAction from "@/views/profile/actions/DocumentsAction";

export default {
    name: 'ActualBooking',
    components: {DocumentsAction, VoucherAction, ChangeAction, CancelAction, Menu},
    data: () => ({
        e1: 1,
        loading: true,
        hotel: {},
        booking: {},
        readMore: false,
        selectedOption: null,
    }),
    mounted() {
        this.$root.$children[0].hideLoading()
        this.$store.dispatch('Profile/fetchBooking')
            .then(() => {
                this.$set(this, 'hotel', this.getBooking().hotel)
                this.$set(this, 'booking', this.getBooking().booking)
                this.loading = false
            })
    },
    methods: {
        ...mapGetters('Profile', ['getBooking']),
        apiUrl() {
            return process.env.VUE_APP_API_URL
        },
        activateReadMore() {
            this.readMore = true;
        },
        stripHtml(value) {
            const div = document.createElement('div')
            div.innerHTML = value
            const text = div.textContent || div.innerText || ''
            return text
        },
        selectOption(option) {
            this.selectedOption = option
        }
    },
}
</script>

<style>
.v-application--is-ltr .v-stepper__label {
    text-align: center !important;
}
</style>