<template>
    <v-col>
        Bitte kontaktieren Sie mich, für eine Änderung der Buchung.

        <div class="text-right">
            <v-btn
                depressed
                color="info"
            >Anfrage senden
            </v-btn>
        </div>
    </v-col>
</template>

<script>

export default {
    name: 'ChangeAction',
    data: () => ({
    }),
    methods: {
    },
}
</script>