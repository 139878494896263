<template>
    <v-col>
        Reise kostenpflichtig stornieren
        Stornokosten: XXXX EUR

        <zw-checkbox v-model="cancel.confirm"
                     name="cancel_confirm"
                     label="Ich möchte meine Reise stornieren"
        ></zw-checkbox>
        <zw-checkbox v-model="cancel.agreement"
                     name="cancel_agreement"
                     label="Ich habe AGB gelesen"
        ></zw-checkbox>
        <zw-checkbox v-model="cancel.cancel"
                     name="cancel_cancel"
                     label="Ich habe Widerrufsrecht gelesen"
        ></zw-checkbox>

        <div class="text-right">
            <v-btn
                depressed
                color="error"
                :disabled="!cancel.agreement || !cancel.cancel || !cancel.confirm || cancel.special"
            >Kostenpflichtig stornieren
            </v-btn>
        </div>

        <zw-checkbox v-model="cancel.special"
                     name="cancel_special"
                     label="Anfrage wegen Sonderstornierung auf Grund von Corona-Einschränkungen"
        ></zw-checkbox>

        <div class="text-right">
            <v-btn
                depressed
                color="error"
                :disabled="!cancel.agreement || !cancel.cancel || !cancel.confirm || !cancel.special"
            >Stornoanfrage senden
            </v-btn>
        </div>
    </v-col>
</template>

<script>


import ZwCheckbox from "@/components/ZwCheckbox";
export default {
    name: 'CancelAction',
    components: {ZwCheckbox},
    data: () => ({
        cancel: {
            confirm: false,
            agreement: false,
            cancel: false,
            special: false,
        },
    }),
    methods: {
    },
}
</script>