<template>
    <v-col>
        Versicherungsschein (generated by operator and uploaded to customer in erika)
        Versicherungsbedingungen*
        Sicherungsschein*
        AGB*
        Widerrufsbelehrung*
        Datenschutz*
    </v-col>
</template>

<script>

export default {
    name: 'DocumentsAction',
    data: () => ({}),
    methods: {},
}
</script>