<template>
    <validation-provider
            v-slot="{ errors }"
            :name="$attrs.name"
            :rules="$props.validation"
    >
        <v-checkbox outlined
                    dense
                    hide-details
                    v-bind="$attrs"
                    v-on="$listeners"
                    :error-messages="errors"
                    @change="(value)=>{$emit('input', value)}"
                    :input-value="$attrs.value"
        >
            <template v-slot:label><slot name="label"></slot></template>
        </v-checkbox>

    </validation-provider>
</template>

<script>
    export default {
        name: 'ZwCheckbox',
        props: {
            validation: {
                type: [String, Object],
            }
        },
    }
</script>